import React from "react";
import Container from '../components/container'
import Layout from "../components/layout";


const ThankYou = (props) =>
    <Layout location={props.location}>
        <Container css={{ flexDirection: `column`, alignItems: `center`, margin: `auto`, justifyContent: `center`, flex: `1 0 80%`, paddingLeft: `18rem` }}>
            <h1>Thank You!</h1>
            <p>We will make every effort to respond to your inquiry in a prompt manner!</p>
        </Container>
    </Layout>
export default ThankYou
